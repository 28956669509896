.header {
  /* background-color: #110028;  */
  padding: 20px;
  margin-top: 15px;
  /* display: flex;
    justify-content: left;
    align-items: center; */
  position: fixed;
  padding: 0 10em;
  z-index: 999;
  /* border-bottom: solid 2px #3a2067 ; */
}

.header-content {
  display: flex;
  align-items: center;
}

.logo {
  height: 70px;
  /* Adjust the height as needed */
  margin-right: 10px;
}

.title {
  color: white;
  font-size: 24px;
  font-family: Arial, sans-serif;
  /* Adjust the font-family as needed */
}
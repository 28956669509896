.footer {
    /* background-color: #10131A;  */
    padding: 10px 0;
    text-align: center;
    color: #A0A4A8;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .footer-content {
    display: flex;
    justify-content:center;
    /* max-width:  570px; */
    /* background-color: ; */
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .footer-content span {
    
    margin: 0  15px;
    /* font-size: 14px; */
  }
  
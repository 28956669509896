@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');
@import "./Assets/_var.scss";

html {
  font-size: 62.5%;
}

body {
  background-position: center;
  background: linear-gradient(180deg, #181920 0%, #01010D 100%);
  min-height: 100dvh;
  color: white;
  // background-image: url("./Assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Rowdies", sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
  margin-bottom: 0;
}

img,
svg,
video,
canvas,
iframe {
  max-width: 100%;
}

a {
  text-decoration: none;
}

a,
span {
  display: inline-block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pointer {
  cursor: pointer !important;
}

.table {
  --bs-table-bg: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

//  z -indexes
.z-xlg {
  z-index: 15 !important;
}

.z-lg {
  z-index: 10 !important;
}

.z-md {
  z-index: 5 !important;
}

.z-low {
  z-index: 1 !important;
}

// toast
.go4109123758 {
  margin-bottom: 1rem;

  .go2072408551 {
    background-color: $toastBg;
    border-radius: 0.8rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 1.6rem;
    color: $white;
    text-align: left;
    font-size: 1.4rem;
    overflow: hidden;

    &::before {
      filter: blur(0.4rem);
    }

    .go3958317564 {
      display: block;

      .small_txt {
        font-size: 1.2rem;
        color: rgba($white, 0.6);
      }
    }

    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $greenClr3;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    &:not(.loading) {
      .go685806154 {
        background-image: url($s3 + "success-icon.png");
        width: 3.2rem;
        height: 3.2rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        flex-shrink: 0;

        div {
          display: none;
        }
      }
    }

    &.loading {
      .go1858758034 {
        width: 2rem;
        height: 2rem;
        border-color: rgba($white, 0.2);
        border-right-color: $greenClr2;
      }
    }

    &.danger {

      &::before,
      &::after {
        background-color: $dangerClr;
      }

      .go685806154 {
        background-image: url($s3 + "danger-icon.png");
      }
    }

    &.warning {

      &::before,
      &::after {
        background-color: $warningClr;
      }

      .go685806154 {
        background-image: url($s3 + "warning-icon.png");
      }
    }

    &.booster_updated {
      .go685806154 {
        background-image: url($s3 + "booster-updated-toast.png");
      }
    }

    &.multitap_updated {
      .go685806154 {
        background-image: url($s3 + "multitap-updated-toast.png");
      }
    }

    &.energy_full_updated {
      .go685806154 {
        background-image: url($s3 + "energy-full-toast.png");
      }
    }
  }

}

button {
  color: $white;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}
@import "../../Assets/_var.scss";


.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  text-align: center;
  padding: 20px;
  color: white;

  h2 {
    color: #FADA65;
  }
}

.connect-button {
  background-color: #00b3ff;
  /* Background color */
  color: #0d0b2d;
  /* Text color */
  border: none;
  border-radius: 8px;
  /* Rounded corners */
  padding: 10px 20px;
  /* Padding for size */
  font-size: 16px;
  /* Font size */
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Box shadow for a subtle effect */
  transition: background-color 0.3s, transform 0.3s;
  /* Smooth transition for hover effects */
}

.connect-button:hover {
  background-color: #008ecc;
  /* Darker background on hover */
  transform: scale(1.05);
  /* Slightly larger on hover */
}

.connect-button:active {
  background-color: #0071a3;
  /* Even darker background on active click */
  transform: scale(1);
  /* Return to original size */
}

.steps_form {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 50%;
      position: relative;

      &:not(:last-child) {
        &::before {
          content: "";
          width: 100%;
          height: 0.2rem;
          border-bottom: 1px dashed white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(0%, -50%);
          z-index: -1;
        }
      }

      button {
        width: 5.4rem;
        height: 5.4rem;
        border-radius: 50%;
        border: 2px solid rgba($white, 0.1);
        background-color: black;
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;

        &.active {
          background: linear-gradient(190deg, black, black) padding-box, linear-gradient(178.88deg, #D98B2F -1.69%, #E4AF48 98.97%) border-box;
          border: 2px solid transparent;
        }

        &.done {
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url($s3 + "checkmark.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

}

.btn-container {
  .in {
    width: 100%;
    max-width: 50rem;
    border-radius: 2rem;
    @extend %border_box;
    padding: 4rem;

    @media (max-width: 575px) {
      padding: 1.6rem;
    }
  }
}

.steps_form_content {
  max-width: 40rem;
  width: 100%;

  .content {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .action_btn {
    display: flex;
    align-items: center;

    >button {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  .copy_area {
    margin: 3.6rem 0 0;
    display: flex;
    align-items: center;

    >p {
      margin-right: 1rem;
      font-size: 1.6rem;
      font-weight: 400;
      background-color: rgba($white, 0.06);
      border-bottom: 1px solid rgba($white, 0.3);
      border-radius: 1rem;
      line-height: 1.8rem;
      height: 4.8rem;
      width: 100%;
      padding: 0 1.7rem;
      display: flex;
      align-items: center;
    }

    button {
      width: 4.8rem;
      padding: 0;
      background-size: auto;
      flex-shrink: 0;
    }
  }
}
@import "../../Assets/_var.scss";

.custom_btn {
    // background: linear-gradient(90deg, #30A7FB 0%, #00B8B1 100%);
    background: linear-gradient(178.88deg, #D98B2F -1.69%, #E4AF48 98.97%);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: $white;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 4.8rem;
    padding: 0 2.2rem;
    border-radius: 1rem;
    position: relative;
    z-index: 1;
    text-shadow: 0.77px 0px 2.3px #00000059;

    @media (max-width: 369px) {
        height: 4.4rem;
        font-size: 1.4rem;
    }

    .dots_loader {
        height: auto;
        min-height: auto;

        .dot {
            background-color: rgba($white, 0.8);
        }
    }

    &:disabled {
        background: $black;
    }

    &.width {
        width: 50%;
    }
}